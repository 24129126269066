<template>
  <h1>Welcome</h1>
  <pre class="pre">
Idempotence&#8480; CA service simplifies management of digital x.509 certificates for multiple purposes. Idempotence&#8480; CA ties certificates to people as certificate authorities, who are able to send connection requests to each other and sign certificates against these connections. All connections are approved by either side to ensure all certificates are tied to physical human entities.

Renewals are managed automatically, and our API can be used to ensure certificates never expire to keep your applications and workflows running smoothly.

Please see:
<a target="_blank" class="hover:text-blue-500 text-blue-600" href="https://api.ca.idempotence.io/api/v1/docs">API Documentation</a>

To configure your own self-managed instance of the Idempotence&#8480; CA, please <a target="_blank" class="hover:text-blue-500 text-blue-600" href="https://calendly.com/idempotence/initial-contact?month=2023-08">schedule a working</a> session with our team. 🚀
</pre>
</template>

<style>
.pre {
  background: #f0eeee;
  border: 1px solid;
  margin: 10px 0;
  padding: 10px;
  font-size: 0.9em;
  white-space: pre-line;
}
</style>
